// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/quality.tsx"
);
import.meta.hot.lastModified = "1732536887588.7898";
}
// REMIX HMR END

class Quality {
    mse: string | null;
    gmsd: string | null;
    psnr: string | null;
    ssim: string | null;
    vmaf: number | null;

    constructor(mse: string | null = null, gmsd: string | null = null, psnr: string | null = null, ssim: string | null = null, vmaf: number | null = null) {
        this.mse = mse;
        this.gmsd = gmsd;
        this.psnr = psnr;
        this.ssim = ssim;
        this.vmaf = vmaf;
    }
    static fromJson(json: any): Quality {
        return new Quality(
            json.mse !== undefined ? json.mse : null,
            json.gmsd !== undefined ? json.gmsd : null,
            json.psnr !== undefined ? json.psnr : null,
            json.ssim !== undefined ? json.ssim : null,
            json.vmaf !== undefined ? json.vmaf : null
        );
    }
}

export default Quality;