// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/Metrics.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/Metrics.tsx");
  import.meta.hot.lastModified = "1734167686042.4106";
}
// REMIX HMR END

const Metrics = ({
  calculating,
  bitrate,
  oldBitrate,
  quality,
  name
}) => {
  const bitrateRatio = parseInt(bitrate) / parseInt(oldBitrate) * 100;
  return <div className={'metrics flex'}>
            <h3>{name}</h3>
            <div className="parameters flex">
                <div className="params1">
                    <p>New Bitrate: </p>
                    <p>MSE: </p>
                    <p>GMSD: </p>
                    <p>PSNR: </p>
                    <p>SSIM: </p>
                    <p>VMAF: </p>
                </div>
                {!calculating && <div className="params2">
                        <p>{bitrate} {bitrate && `(${bitrateRatio.toFixed(3)}% of original bitrate)`}</p>
                        <p>{quality.mse != null ? parseFloat(quality.mse).toFixed(1) : ' '}</p>
                        <p>{quality.gmsd != null ? parseFloat(quality.gmsd).toFixed(3) : ' '}</p>
                        <p>{quality.psnr != null ? parseFloat(quality.psnr).toFixed(3) : ' '}</p>
                        <p>{quality.ssim != null ? parseFloat(quality.ssim).toFixed(3) : ' '}</p>
                        <p>{quality.vmaf != null ? quality.vmaf.toFixed(3) : ' '}</p>
                    </div>}

            </div>
        </div>;
};
_c = Metrics;
export default Metrics;
var _c;
$RefreshReg$(_c, "Metrics");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;